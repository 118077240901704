<template>
  <Overview />
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useTexts from "@/composables/useTexts";
// Components
import Overview from "../overview/Overview";

export default {
  components: {
    Overview
  },
  props: {
    resource: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Composables
    const { getText } = useTexts();

    // Computed
    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${t("app.overview")} - ${t("app.observations", 2)} - ${t("app.evaluations", 2)} - ${t("app.teaching")}`;

      const name = getText(props.resource?.texts, "name");
      if (name) {
        return `${name} - ${title}`;
      }

      return title;
    });

    return {
      documentTitle
    };
  }
};
</script>
